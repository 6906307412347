export default function SvgRiseLogoText(props) {
  return (
    <svg fill="none" viewBox="0 0 205 57" {...props}>
      <g fill="#fff" clip-path="url(#clip0_87_43)">
        <path d="M200.19 33.7h-32.66V23.3h32.66v10.4Zm4.65 23.3h-37.3c-1.44 0-3-.25-4.7-.76a14.2 14.2 0 0 1-4.73-2.46 14.6 14.6 0 0 1-3.62-4.5 14.72 14.72 0 0 1-1.43-6.79V5.21a5.46 5.46 0 0 1 1.47-3.7A5.12 5.12 0 0 1 158.27 0h46.57v10.33H163.4V42.5c0 1.35.36 2.39 1.07 3.1.72.72 1.77 1.08 3.14 1.08h37.23V57ZM144.7 40.23c0 2.04-.26 3.88-.8 5.52a16.05 16.05 0 0 1-2.02 4.3 13.66 13.66 0 0 1-2.98 3.17 18.29 18.29 0 0 1-7.28 3.38c-1.25.27-2.43.4-3.54.4h-39.9V46.67h39.9c1.99 0 3.52-.59 4.61-1.75a6.5 6.5 0 0 0 1.67-4.7c0-.95-.14-1.82-.44-2.62a5.9 5.9 0 0 0-3.26-3.42 6.7 6.7 0 0 0-2.58-.47h-23.79c-1.67 0-3.47-.3-5.4-.88a16.76 16.76 0 0 1-5.42-2.9 16.07 16.07 0 0 1-4.13-5.2 17.28 17.28 0 0 1-1.64-7.88c0-3.1.55-5.7 1.64-7.83a15.8 15.8 0 0 1 9.55-8.1c1.93-.62 3.73-.92 5.4-.92h35.2v10.33h-35.2c-1.96 0-3.5.6-4.61 1.8a6.75 6.75 0 0 0-1.63 4.72c0 2 .54 3.57 1.63 4.73 1.11 1.14 2.65 1.71 4.61 1.71H128.16c1.11.03 2.29.19 3.54.48a17.23 17.23 0 0 1 7.24 3.5 15.34 15.34 0 0 1 5.01 7.47c.5 1.62.75 3.45.75 5.49ZM77.8 57H67.46V0H77.8v57ZM57 19.16c0 2.36-.3 4.46-.88 6.32a17.74 17.74 0 0 1-2.38 4.93 16.48 16.48 0 0 1-3.42 3.62 19.82 19.82 0 0 1-8.4 3.9c-1.43.28-2.78.43-4.05.43L59.47 57h-16L21.92 38.36h-7.44V28.02h23.39c1.3-.1 2.48-.37 3.54-.8a8.82 8.82 0 0 0 2.78-1.78c.8-.74 1.4-1.63 1.83-2.66.42-1.06.64-2.27.64-3.62v-6.52c0-.58-.08-1.02-.24-1.31a1.31 1.31 0 0 0-.56-.68 1.4 1.4 0 0 0-.71-.28 6.14 6.14 0 0 0-.68-.04H10.34V57H0V5.2a5.47 5.47 0 0 1 1.47-3.7A5.13 5.13 0 0 1 5.21.01h39.26c2.3 0 4.26.42 5.85 1.27 1.59.82 2.87 1.87 3.85 3.14 1.01 1.25 1.73 2.6 2.15 4.06.45 1.45.68 2.82.68 4.1v6.59Z" />
      </g>
      <defs>
        <clipPath id="clip0_87_43">
          <path fill="#fff" d="M0 0h205v57H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
